import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';
import DescribedIcon from '../DescribedIcon/DescribedIcon';

import { ISizesBlockProps } from './models';
import './SizesBlock.scss';

const SizesBlock: FC<ISizesBlockProps> = ({ title, subtitle, icons, description }) => (
  <section className="sizes-block" data-testid="SizesBlock">
    <DangerouslySetInnerHtml html={subtitle} className="sizes-block__subtitle" />
    <DangerouslySetInnerHtml html={title} className="sizes-block__title" />
    <div className="sizes-block__content">
      <div className="sizes-block__sizes">
        {icons.map((icon) => (
          <DescribedIcon
            {...icon.properties}
            key={icon.properties.title}
            className="described-icon__sizes"
          />
        ))}
      </div>
      <DangerouslySetInnerHtml html={description} className="sizes-block__description" />
    </div>
  </section>
);

export default SizesBlock;

export const query = graphql`
  fragment FragmentSizesBlock on SizesBlockTypes {
    properties {
      title
      subtitle
      description
      icons {
        ...FragmentDescribedIcon
      }
    }
  }
`;
