import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, EffectFade } from 'swiper';
import useScreenRecognition from 'hooks/useScreenRecognition';

import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.css';

import { DangerouslySetInnerHtml } from 'layout';
import FeatureItem from '../FeatureItem/FeatureItem';

import { IProductsBlockProps } from './models';
import './ProductsBlock.scss';

SwiperCore.use([Navigation, Pagination, EffectFade]);

const ProductsBlock: FC<IProductsBlockProps> = ({ title, features }) => {
  const { isLargeDesktop } = useScreenRecognition();

  return (
    <section className="products-block" data-testid="ProductsBlock">
      <DangerouslySetInnerHtml html={title} className="products-block__title" />
      {isLargeDesktop === false ? (
        <div className="products-block__slider">
          <Swiper
            slidesPerView={1}
            pagination={{ clickable: true }}
            navigation
            effect="fade"
            fadeEffect={{ crossFade: true }}
          >
            {features?.map((featureSlide) => (
              <SwiperSlide key={featureSlide.properties.icon[0].properties.title}>
                <FeatureItem {...featureSlide.properties} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : null}
      {isLargeDesktop ? (
        <div className="products-block__grid">
          {features?.map((featureColumn) => (
            <FeatureItem
              {...featureColumn.properties}
              key={featureColumn.properties.icon[0].properties.title}
            />
          ))}
        </div>
      ) : null}
    </section>
  );
};

export default ProductsBlock;

export const query = graphql`
  fragment FragmentProductsBlock on ProductsBlockTypes {
    properties {
      title
      features {
        ...FragmentFeatureItem
      }
    }
  }
`;
