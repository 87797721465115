import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';
import DescribedIcon from '../DescribedIcon/DescribedIcon';

import { IFeaturesBlockProps } from './models';
import './FeaturesBlock.scss';

const FeaturesBlock: FC<IFeaturesBlockProps> = ({ title, description, icons }) => (
  <section className="features-block" data-testid="FeaturesBlock">
    <DangerouslySetInnerHtml html={title} className="features-block__title" />
    <DangerouslySetInnerHtml html={description} className="features-block__description" />
    <div className="features-block__content">
      {icons.map((icon) => (
        <DescribedIcon
          {...icon.properties}
          key={icon.properties.title}
          className="described-icon__features"
        />
      ))}
    </div>
  </section>
);
export default FeaturesBlock;
export const query = graphql`
  fragment FragmentFeaturesBlock on FeaturesBlockTypes {
    properties {
      title
      description
      icons {
        ...FragmentDescribedIcon
      }
    }
  }
`;
