import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Container, Layout } from 'layout';

import HeroBanner from 'components/ArticleListingHeroBanner';
import HeadingBlock from 'components/HeadingBlock/HeadingBlock';
import SizesBlock from 'components/SizesBlock/SizesBlock';
import FeaturesBlock from 'components/FeaturesBlock/FeaturesBlock';
import ProductsBlock from 'components/ProductsBlock/ProductsBlock';

import { IFindYourBestFitPageComponentProps } from './models';
import './FindYourBestFitPage.scss';

const FindYourBestFitPage: FC<IFindYourBestFitPageComponentProps> = ({
  data: {
    pageData: {
      url,
      defaultCompositions,
      seoMetaKeywords,
      seoMetaTitle,
      seoMetaDescription,
      seoExternalHreflangs,
      heroBanner,
      headingBlock,
      sizesBlock,
      featuresBlock,
      productsBlock,
    },
  },
}) => (
  <Layout
    defaultCompositions={defaultCompositions}
    seo={{
      seoMetaKeywords,
      seoMetaTitle,
      seoMetaDescription,
      seoExternalHreflangs,
    }}
    className="find-your-best-fit"
  >
    <Container fluid>
      {heroBanner.map(({ properties: { image, imageAlt, title } }) => (
        <HeroBanner
          key={title}
          inFirstViewport
          image={image}
          altImageText={imageAlt}
          title={title}
          hasVerticalPadding={false}
          url={url}
          className="find-your-best-fit__hero-banner"
        />
      ))}
      <Container>
        <div className="container__inside">
          <HeadingBlock {...headingBlock[0].properties} />
        </div>
      </Container>
      <ProductsBlock {...productsBlock[0].properties} />
      <Container>
        <div className="container__inside">
          <SizesBlock {...sizesBlock[0].properties} />
          <FeaturesBlock {...featuresBlock[0].properties} />
        </div>
      </Container>
    </Container>
  </Layout>
);

export const query = graphql`
  query($url: String, $lang: String) {
    pageData: umbracoFindYourBestFit(url: { eq: $url }, lang: { eq: $lang }) {
      url
      pageName
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      heroBanner {
        properties {
          title
          imageAlt
          description
          image {
            childImageSharp {
              fluid(maxWidth: 1500, quality: 88) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      headingBlock {
        ...FragmentHeadingBlock
      }
      sizesBlock {
        ...FragmentSizesBlock
      }
      featuresBlock {
        ...FragmentFeaturesBlock
      }
      productsBlock {
        ...FragmentProductsBlock
      }
    }
  }
`;

export default FindYourBestFitPage;
