import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';

import { IHeadingBlockProps } from './models';
import './HeadingBlock.scss';

const HeadingBlock: FC<IHeadingBlockProps> = ({ title, description }) => (
  <section className="heading-block" data-testid="HeadingBlock">
    <DangerouslySetInnerHtml element="h1" html={title} className="heading-block__title" />
    <DangerouslySetInnerHtml html={description} className="heading-block__description" />
  </section>
);

export default HeadingBlock;

export const query = graphql`
  fragment FragmentHeadingBlock on HeadingBlockTypes {
    properties {
      title
      description
    }
  }
`;
