import React, { FC } from 'react';
import { graphql } from 'gatsby';

import DescribedIcon from '../DescribedIcon/DescribedIcon';
import SizeItem from '../SizeItem/SizeItem';

import { IFeatureItemProps } from './models';
import './FeatureItem.scss';

const FeatureItem: FC<IFeatureItemProps> = ({ icon, sizeS, sizeM, sizeL }) => (
  <div className="feature-item" data-testid="FeatureItem">
    <div className="feature-item__icon">
      <DescribedIcon {...icon[0].properties} className="described-icon__feature-item" />
    </div>
    <div className="feature-item__sizes">
      {sizeS ? <SizeItem {...sizeS[0].properties} className="size-item__small" /> : null}
      {sizeM ? <SizeItem {...sizeM[0].properties} className="size-item__medium" /> : null}
      {sizeL ? <SizeItem {...sizeL[0].properties} className="size-item__large" /> : null}
    </div>
  </div>
);

export default FeatureItem;

export const query = graphql`
  fragment FragmentFeatureItem on FeatureItemTypes {
    properties {
      sizeS {
        ...FragmentSizeItem
      }
      sizeM {
        ...FragmentSizeItem
      }
      sizeL {
        ...FragmentSizeItem
      }
      icon {
        ...FragmentDescribedIcon
      }
    }
  }
`;
