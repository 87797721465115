import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';
import classNames from 'classnames';

import GatsbyImage from 'common/GatsbyImage';

import DescribedIcon from '../DescribedIcon/DescribedIcon';

import { ISizeItemProps } from './models';
import './SizeItem.scss';

const SizeItem: FC<ISizeItemProps> = ({ icon, productList, className }) => (
  <div className={classNames('size-item', className)} data-testid="SizeItem">
    <DescribedIcon {...icon[0].properties} className="described-icon__sizeItem" />
    {productList?.length ? (
      <div className="size-item__product-list">
        {productList.map((productItem) => (
          <Link to={productItem.properties.url[0].url} key={productItem.properties.url[0].url}>
            <GatsbyImage
              className="size-item__product"
              objectFit="contain"
              fluid={productItem.properties.image.gatsbyImage.childImageSharp.fluid}
              alt={productItem.properties.image.altText}
            />
          </Link>
        ))}
      </div>
    ) : null}
  </div>
);

export default SizeItem;

export const query = graphql`
  fragment FragmentSizeItem on SizeItemTypes {
    properties {
      productList {
        properties {
          image {
            altText
            fallbackUrl
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          url {
            url
          }
        }
      }
      icon {
        ...FragmentDescribedIcon
      }
    }
  }
`;
