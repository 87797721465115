import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/layout/DangerouslySetInnerHtml';

import { IDescribedIconProps } from './models';
import './DescribedIcon.scss';

const DescribedIcon: FC<IDescribedIconProps> = ({ title, description, icon, className }) => (
  <div className={classNames('described-icon', className)} data-testid="DescribedIcon">
    {icon.svg ? (
      <DangerouslySetInnerHtml
        html={icon.svg?.content}
        aria-label={icon.altText}
        className="described-icon__icon"
      />
    ) : null}
    <div className="described-icon__content">
      <DangerouslySetInnerHtml html={title} className="described-icon__title" />
      {description ? (
        <DangerouslySetInnerHtml html={description} className="described-icon__description" />
      ) : null}
    </div>
  </div>
);

export default DescribedIcon;

export const query = graphql`
  fragment FragmentDescribedIcon on DescribedIconTypes {
    properties {
      title
      description
      icon {
        altText
        url
        svg {
          content
        }
      }
    }
  }
`;
